import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
  
const theme = responsiveFontSizes(createMuiTheme({
    spacing: 4,
    typography: {
        fontFamily: [
            'Segoe UI',
            'Roboto',
            'Open Sans',
            'Helvetica Neue',
            'sans-serif'
        ].join(','),
    },
    palette: {
        background: {
            default: '#FFFFFF'//white
        },
        primary: {
            main: '#e1101a', //red 
            other: '#000000', 
        },
        secondary: {
            main: '#FFFFFF', 
            other: '#adadad',//gray
        },
        error: {
            main: '#D72A2A',//red
        },
        warning: {
            main: '#D72A2A',//red
        },
        success: {
            main: '#09FE00',//green
        },
        disabled: {
            main: '#e0e0e0',//light gray
        },
        pointout: {
            main: '#D72A2A',//red
        },
        text: {
            primary: '#000000',
            secondary: '#adadad',//gray
        },
        link: {
            main: '#FFFFFF'
        }
    },
}));

export default theme;